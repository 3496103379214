import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "productTypes": [{
    "body": "Designed for tracking cargo on land and sea. Follow the cargo fromport ofloading to place of delivery, get information on ETAs, monitor conditions, create automated status reports and alerts and keep records of deliveries and trips.",
    "identification": "productTypes",
    "image": "/img/ProductTypeSpoor.png",
    "name": "Spoor",
    "publish": true,
    "subtitle": "Track cargo from any origin to any destination.",
    "tinyImage": "/img/ProductTypeSpoorMini.png"
  }, {
    "body": "Designed for monitoring project cargo on land and sea. Follow the cargo from loading port through yard to site, know who delivers what, monitor conditions (speed, shock, etc.) and keep records of deliveries, trips and drivers.",
    "identification": "productTypes",
    "image": "/img/ProductTypeSpecialCargopng.png",
    "name": "Spoor Special Cargo",
    "publish": true,
    "subtitle": "Track cargo from any origin to the same destination.",
    "tinyImage": "/img/ProjectBasedMini.png"
  }, {
    "body": "Designed for tracking passive and activeequipment. Know where yourequipment is, whetherit isloadedordischarged,where it was in the past andwhereitneedsto bein the future.",
    "identification": "productTypes",
    "image": "/img/ProjectBased.png",
    "name": "SpoorEQ ",
    "publish": false,
    "subtitle": "Track equipment wherever it is. ",
    "tinyImage": "/img/ProjectBasedMini.png"
  }]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      